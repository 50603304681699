/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";

html, body{
}

app-root{
}

:root{
	--black: #000;
	--white: #fff;

	--main: #009540;
	--second: #6eb520;

	--kuv-bg-options: var(--second);
	--kuv-cl-options: var(--white);

	--kuv-bg-children: var(--second);
	--kuv-cl-children: var(--white);
	
	--kuv-bg-hover: var(--main);
	--kuv-cl-hover: var(--white);

	--kuv-bg-active: var(--main);
	--kuv-cl-active: var(--white);

	--kuv-bg-logo: var(--main);
	--kuv-cl-logo: var(--white);

	--kuv-bg-toggle: #007432;
	--kuv-cl-toggle: var(--white);

	--kt-head-bg: var(--main);
	--kt-head-cl: var(--white);

	// --kuv-cl-icon: var(--white);
	
	// --kuv-table-bg-body: white;
	// --kuv-table-bg-body-2: red;
	// --kuv-table-cl-body: black;

	// --kuv-table-bg-head: #505556;
	// --kuv-table-cl-head: white;

	// --kuv-table-b-head: white;
	// --kuv-table-bg-head-hover: white;
	// --kuv-table-cl-head-hover: black;
	// --kuv-table-b-foot: white;
	// --kuv-table-bg-foot: #505556;
	// --kuv-table-cl-foot: white;
	// --kuv-table-bg-body-2: white;
}

.bg-gray{
	background-color: #fafafa;
}

hr.rounded {
    border-top: 2px solid #bbbbbb;
    border-radius: 1px;
	width: 100%;
}

.k-table{
	input, select {
		border: 1px solid rgba($color: #000000, $alpha: .3) !important;
	}
	.k-foot{
		border-top: 1px solid rgba($color: #000000, $alpha: .3) !important;
		.k-row{
			border: none !important;
		}
	}
	
	.k-head{
		border-bottom: 1px solid rgba($color: #000000, $alpha: .3) !important;
		.k-row{
			border: none !important;
		}
	}
	
	.k-cell.k-action-cell{
		display: flex;
		div:not(:last-child){
			margin-right: .5rem;
		}
		.k-action-name{
			background: white !important;
		}
	}
}


// .modal-content{
// 	background-color: none !important;
// 	background: none !important;
// }

#ngx-kuv-sidebar-option-text{
	line-height: 1.1;
}

div#kuv-sidebar .main-container .options-container .icon {
    color: var(--kuv-cl-icon) !important;
}

#kuv-sidebar{
	a, button{
		text-decoration: none !important;
	}
}

.popover-header {
	padding-top: 10px;
	color: black !important;
	text-align: center;
}

.popover {
	width: 400px;
	height: 60%;
	overflow-y : scroll;
}

.popover-content {
	overflow-y : scroll;
	height: 60%;  
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@keyframes rotate {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.rotate{
	animation-name: rotate;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

.g-3, .gap-3{
	gap: 1rem;
}
.g-2, .gap-2{
	gap: .5rem;
}

.kuv-lazy-table{
	.cell.acciones:not(.header){
		white-space: nowrap !important;
		flex-wrap: nowrap !important;
		justify-content: flex-end !important;
	}

}

.text-end{
	text-align: end;
}

ng-select div.ng-select-container {
	border-radius: 4px !important;
	border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.form-group {
	margin-bottom: 1rem;
}